.dashboard .email {
  display: flex;
}

.dashboard .form-control {
  min-width: 120px;
}

.dashboard .table-container {
  width: 100%;
  overflow-x: auto;
}

.dashboard .thumbnail {
  max-width: 50px;
  max-height: 50px;
}

.dashboard .link {
  cursor: pointer;
}

.dashboard .approve {
  color: green;
}

.dashboard .deny, .dashboard .cancel {
  color: red;
}

.cancelButton {
  text-align: center;
}

.dialog .full-image {
  max-width: 100%;
}

.dialog .approve {
  color: #fff;
  background-color: green;
}

.dialog .deny {
  color: #fff;
  background-color: red;
}

.dialog .divider {
  width: 100%;
  height: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.loadingBar {
  z-index: 2;
  background-color: #00000017;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.daterangepicker {
  color: inherit;
  z-index: 3001;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 278px;
  max-width: none;
  margin-top: 7px;
  padding: 0;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
  display: none;
  position: absolute;
  top: 100px;
  left: 20px;
}

.daterangepicker:before, .daterangepicker:after {
  content: "";
  border-bottom-color: #0003;
  display: inline-block;
  position: absolute;
}

.daterangepicker:before {
  border-bottom: 7px solid #ccc;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  top: -7px;
}

.daterangepicker:after {
  border-bottom: 6px solid #fff;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  top: -6px;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before, .daterangepicker.openscenter:after {
  width: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  border-bottom: initial;
  border-top: 7px solid #ccc;
  bottom: -7px;
}

.daterangepicker.drop-up:after {
  top: initial;
  border-bottom: initial;
  border-top: 6px solid #fff;
  bottom: -6px;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar, .daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  max-width: 270px;
  display: none;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: 2px solid #000;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  padding: 3px;
  display: inline-block;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 4px;
  width: 32px;
  min-width: 32px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
}

.daterangepicker .calendar-table {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
}

.daterangepicker .calendar-table table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  color: inherit;
  background-color: #eee;
  border-color: #0000;
}

.daterangepicker td.week, .daterangepicker th.week {
  color: #ccc;
  font-size: 80%;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  color: #999;
  background-color: #fff;
  border-color: #0000;
}

.daterangepicker td.in-range {
  color: #000;
  background-color: #ebf4f8;
  border-color: #0000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  color: #fff;
  background-color: #357ebd;
  border-color: #0000;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  cursor: default;
  height: auto;
  margin: 0;
  padding: 1px;
  font-size: 12px;
}

.daterangepicker select.monthselect {
  width: 56%;
  margin-right: 2%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  background: #eee;
  border: 1px solid #eee;
  outline: 0;
  width: 50px;
  margin: 0 auto;
  padding: 2px;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  vertical-align: middle;
  border-top: 1px solid #ddd;
  padding: 8px;
  line-height: 12px;
  display: none;
}

.daterangepicker .drp-selected {
  padding-right: 8px;
  font-size: 12px;
  display: inline-block;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.daterangepicker .ranges li {
  cursor: pointer;
  padding: 8px 12px;
  font-size: 12px;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  color: #fff;
  background-color: #08c;
}

@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    float: left;
    width: auto;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}

.photoUpload input {
  height: inherit;
  display: inherit;
}

.container .reimbursementForm {
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 12px;
  font-family: Montserrat, sans-serif;
}

.container .reimbursementForm label {
  margin-bottom: 5px;
  display: block;
}

.container .reimbursementForm h1 {
  font-weight: 400;
}

.container .reimbursementForm p {
  margin-bottom: 20px;
  font-size: 14px;
}

.container .reimbursementForm input, .container .reimbursementForm textarea, .container .reimbursementForm select {
  border: 1px solid #ddd;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 8px;
  font-size: 12px;
}

.container .reimbursementForm .uploaderFormControl {
  margin-right: 8px;
}

.container .reimbursementForm .submitterEmail {
  margin-bottom: 18px;
}

.container .reimbursementForm .submitterEmail span {
  text-decoration: underline;
}

.container .reimbursementForm select, .container .reimbursementForm #expenseDate {
  width: 200px;
}

.container .reimbursementForm #receipts {
  margin-top: 20px;
}

.container .reimbursementForm #submitButton {
  color: #fff;
  cursor: pointer;
  z-index: 1;
  background-color: #b92222;
  width: 100%;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.container .reimbursementForm #submitButton:disabled, .container .reimbursementForm #submitButton[disabled] {
  color: #666;
  background-color: #ccc;
  border: 1px solid #999;
}

.container .loadingBar {
  z-index: 2;
  background-color: #00000017;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.container .errorMessage {
  color: red;
  font-size: larger;
  font-weight: 600;
}

.container .expenseForm {
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 12px;
  font-family: Montserrat, sans-serif;
}

.container .expenseForm label {
  margin-bottom: 5px;
  display: block;
}

.container .expenseForm h1 {
  font-weight: 400;
}

.container .expenseForm p {
  margin-bottom: 20px;
  font-size: 14px;
}

.container .expenseForm input, .container .expenseForm textarea, .container .expenseForm select {
  border: 1px solid #ddd;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 8px;
  font-size: 12px;
}

.container .expenseForm .uploaderFormControl {
  margin-right: 8px;
}

.container .expenseForm .submitterEmail {
  margin-bottom: 18px;
}

.container .expenseForm .submitterEmail span {
  text-decoration: underline;
}

.container .expenseForm select, .container .expenseForm #expenseDate {
  width: 200px;
}

.container .expenseForm #receipts {
  margin-top: 20px;
}

.container .expenseForm #submitButton {
  color: #fff;
  cursor: pointer;
  z-index: 1;
  background-color: #b92222;
  width: 100%;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.container .expenseForm #submitButton:disabled, .container .expenseForm #submitButton[disabled] {
  color: #666;
  background-color: #ccc;
  border: 1px solid #999;
}

.container .loadingBar {
  z-index: 2;
  background-color: #00000017;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.container .errorMessage {
  color: red;
  font-size: larger;
  font-weight: 600;
}

.confirmation {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 12px;
}

.confirmation h1 {
  color: #3cb371;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-family: Montserrat, sans-serif;
  font-size: 4em;
  font-weight: 700;
  line-height: 1.5em;
}

.confirmation h2 {
  color: #000;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  line-height: 1.5em;
}

.confirmation h3 {
  color: #696969;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
}

@media (max-width: 525px) {
  .confirmation h1 {
    font-size: 2em;
  }
}

/*# sourceMappingURL=index.133a0ee6.css.map */
