.confirmation {
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 12px;
  text-align: center; }
  .confirmation h1 {
    font-size: 4em;
    color: #3CB371;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 700;
    line-height: 1.5em;
    font-family: 'Montserrat', sans-serif; }
  .confirmation h2 {
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 600;
    line-height: 1.5em;
    font-family: 'Montserrat', sans-serif; }
  .confirmation h3 {
    color: #696969;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif; }
  @media (max-width: 525px) {
    .confirmation h1 {
      font-size: 2em; } }
