.dashboard .email {
  display: flex; }

.dashboard .form-control {
  min-width: 120px; }

.dashboard .table-container {
  width: 100%;
  overflow-x: auto; }

.dashboard .thumbnail {
  max-width: 50px;
  max-height: 50px; }

.dashboard .link {
  cursor: pointer; }

.dashboard .approve {
  color: green; }

.dashboard .deny, .dashboard .cancel {
  color: red; }

.cancelButton {
  text-align: center; }

.dialog .full-image {
  max-width: 100%; }

.dialog .approve {
  color: white;
  background-color: green; }

.dialog .deny {
  color: white;
  background-color: red; }
 
.dialog .divider {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 2px; }

.loadingBar {
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.09);
  z-index: 2;
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center; 
}
