.container .reimbursementForm {
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 12px;
  font-family: Montserrat, sans-serif; }
  .container .reimbursementForm label {
    display: block;
    margin-bottom: 5px; }
  .container .reimbursementForm h1 {
    font-weight: 400; }
  .container .reimbursementForm p {
    font-size: 14px;
    margin-bottom: 20px; }
  .container .reimbursementForm input, .container .reimbursementForm textarea, .container .reimbursementForm select {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 12px;
    padding: 8px;
    width: 100%;
    height: 100%;
    border: 1px solid #DDDDDD; }
  .container .reimbursementForm .uploaderFormControl {
    margin-right: 8px; }
  .container .reimbursementForm .submitterEmail {
    margin-bottom: 18px; }
    .container .reimbursementForm .submitterEmail span {
      text-decoration: underline; }
  .container .reimbursementForm select, .container .reimbursementForm #expenseDate {
    width: 200px; }
  .container .reimbursementForm #receipts {
    margin-top: 20px; }
  .container .reimbursementForm #submitButton {
    margin-top: 20px;
    background-color: #b92222;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    z-index: 1; }
  .container .reimbursementForm #submitButton:disabled,
  .container .reimbursementForm #submitButton[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666; }

.container .loadingBar {
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.09);
  z-index: 2;
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center; }

.container .errorMessage {
  color: red;
  font-size: larger;
  font-weight: 600; }
